import { firestore } from "firebase";

export type Stock = {
  id: string;
  name: string;
  price: number;
  supplier: string;
  supplierId: string;
  category: string;
  categoryId: string;
  amount: number;
  minimumOrderQuantity: number;
  stockInDates: firestore.Timestamp[];
};

export type Supplier = {
  id: string;
  name: string;
};

export type Category = {
  id: string;
  name: string;
};

export type Order = {
  id: string;
  item: string;
  stockId: string;
  supplier: string;
  quantity: number;
  status: OrderStatus;
  createdAt: firestore.Timestamp;
};

export enum OrderStatus {
  Registered = "登録済み",
  Ordered = "発注済み",
  Delivered = "受け取り済み",
}

export type GlobalState = {
  isLoggedIn: boolean;
  stocks: Stock[];
  suppliers: Supplier[];
  categories: Category[];
};
