import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { SignInPage } from "../pages/SignInPage";

export const AuthRoutes = () => {
  return (
    <Switch>
      <Route exact path="/auth/signin" component={SignInPage} />
      <Redirect to="/auth/signin" from="/auth" />
    </Switch>
  );
};
