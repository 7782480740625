import React, { useEffect, useState } from "react";
import { auth } from "../Firebase";
import * as H from "history";

type AuthContextProps = {
  currentUser: firebase.User | null;
  didSigninFailed: boolean;
  didSomeErrorHappended: boolean;
  signin: (email: any, password: any, afterSignin: () => void, history: any) => void;
  signout: () => void;
  loadingAuthState: boolean;
};

export const AuthContext = React.createContext<Partial<AuthContextProps>>({});
export const AuthProvider = ({ children }: any) => {
  const [currentUser, setCurrentUser] = useState(null as firebase.User | null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const [didSigninFailed, setDidSigninFailed] = useState(false);
  const [didSomeErrorHappended, setDidSomeErrorHappended] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((user: any) => {
      setCurrentUser(user);
      setLoadingAuthState(false);
    });
  }, []);

  const signin = (email: any, password: any, afterSignin: () => void, history: H.History) => {
    (async () => {
      setLoadingAuthState(true);
      setDidSomeErrorHappended(false);
      setDidSigninFailed(false);
      try {
        await auth.signInWithEmailAndPassword(email, password);
        afterSignin();
        setLoadingAuthState(false);
        history.push("/main");
      } catch (e) {
        if (e.code === "auth/wrong-password") {
          setDidSigninFailed(true);
        } else {
          setDidSomeErrorHappended(true);
        }
        setLoadingAuthState(false);
        afterSignin();
      }
    })();
  };

  const signout = () => {
    (async () => {
      setLoadingAuthState(true);
      try {
        await auth.signOut();
        setLoadingAuthState(false);
      } catch (e) {
        setLoadingAuthState(false);
      }
    })();
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        signin,
        signout,
        loadingAuthState,
        didSigninFailed,
        didSomeErrorHappended,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
