import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DeleteIcon from "@material-ui/icons/Delete";
import { SuppliersContext } from "../../../contexts/suppliers";

type DeleteSupplierFormDialogProps = {
  supplierId: string;
  handleEditDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
  })
);

export const DeleteSupplierFormDialog: React.FC<DeleteSupplierFormDialogProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const { handleEditDialog } = props;
  const classes = useStyles();

  const { remove } = useContext(SuppliersContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDeleteButtonClicked = () => {
    remove && remove(props.supplierId);
    setOpen(false);
    handleEditDialog(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<DeleteIcon />}
        onClick={handleClickOpen}
        className={classes.button}
        size="small"
      >
        削除
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"この仕入先データを本当に削除していいですか？"}
        </DialogTitle>
        <DialogContentText color="secondary" align="center">
          当該分類の品目データも全て削除されます。
        </DialogContentText>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            キャンセル
          </Button>
          <Button onClick={handleDeleteButtonClicked} color="secondary">
            削除する
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
