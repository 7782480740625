import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD5Fbq73ElWZJIsK1ONGawRr8aJ8gfnJT0",
  authDomain: "stock-management-5e7f3.firebaseapp.com",
  databaseURL: "https://stock-management-5e7f3.firebaseio.com",
  projectId: "stock-management-5e7f3",
  storageBucket: "stock-management-5e7f3.appspot.com",
  messagingSenderId: "1060310993269",
  appId: "1:1060310993269:web:49e876a4083a0adc86eb3c",
  measurementId: "G-7VJWJLRF1B",
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();

if (window.location.hostname === "localhost") {
  console.log("localhost detected!");
  db.settings({
    host: "localhost:8080",
    ssl: false,
  });
}

export { firebase, db, auth };
