import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import { OrdersContext } from "../../../contexts/orders";
import { Stock, OrderStatus } from "../../../types";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { InputErrorText } from "../../utils/InputErrorText";

type AddOrderStatusFormDialogProps = {
  stock: Stock;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
    errorText: {
      color: "red",
    },
  })
);

export const AddOrderFormDialog: React.FC<AddOrderStatusFormDialogProps> = (props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [isInvalidValue, setIsInvalidValue] = React.useState<boolean>(false);
  const [isLessThanOne, setIsLessThanOne] = React.useState<boolean>(false);
  const { name, supplier, id } = props.stock;

  const [quantity, setQuantity] = React.useState<number>(0);
  const handleQuantityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setQuantity(event.target.value as number);
  };

  const classes = useStyles();
  const { add } = useContext(OrdersContext);

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsInvalidValue(false);
    setIsLessThanOne(false);
    setQuantity(0);
  };

  const handleAddButtonClicked = () => {
    setIsInvalidValue(false);
    setIsLessThanOne(false);

    if ((quantity as unknown) === "") {
      setIsInvalidValue(true);
      return;
    }

    if (quantity < 1) {
      setIsLessThanOne(true);
      return;
    }

    add &&
      add({
        id: "",
        item: name,
        stockId: id,
        quantity: +quantity,
        supplier,
        status: OrderStatus.Registered,
      });

    handleClose();
  };

  return (
    <form>
      <IconButton
        aria-label="search"
        className={classes.button}
        color="primary"
        onClick={handleClickOpen}
      >
        <LocalShippingIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">発注登録</DialogTitle>

        <DialogContent>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="item">品目</InputLabel>
            <Input id="item" value={name} readOnly />
          </FormControl>

          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="supplier">発注先</InputLabel>
            <Input id="supplier" value={supplier} readOnly />
          </FormControl>

          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="quantity">数量</InputLabel>

            <Input
              id="quantity"
              value={quantity}
              type="number"
              required
              onChange={handleQuantityChange}
              error={isInvalidValue}
            />
            {isInvalidValue && <InputErrorText message="入力は必須です" />}
            {isLessThanOne && <InputErrorText message="1以上の値を入力してください" />}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleAddButtonClicked} color="primary">
            登録
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
