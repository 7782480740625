import React, { useState, useMemo } from "react";
import { db } from "../Firebase";
import { Supplier } from "../types";

type SuppliersContextProps = {
  suppliers: Supplier[] | null;
  add: (supplier: Omit<Supplier, "id">) => void;
  update: (supplier: Supplier) => void;
  remove: (id: string) => void;
};

const buildSupplier = (data: firebase.firestore.DocumentData) => {
  const supplier: Supplier = {
    id: data.id,
    name: data.name,
  };

  return supplier;
};

export const SuppliersContext = React.createContext<Partial<SuppliersContextProps>>({});

export const SuppliersProvider = ({ children }: any) => {
  const [suppliers, setSuppliers] = useState<Supplier[] | null | undefined>(undefined);

  const collection = useMemo(() => {
    const fetchData = async () => {
      const col = await db.collection("suppliers");
      col.onSnapshot((query) => {
        const data: Supplier[] = [];
        query.forEach((d) => {
          const supplier = buildSupplier({ ...d.data(), id: d.id });
          data.push(supplier);
        });
        setSuppliers(data);
      });
      return col;
    };
    return fetchData();
  }, []);

  const add = (supplier: Omit<Supplier, "id">) => {
    (async () => {
      (await collection).add({
        ...supplier,
      });
    })();
  };

  const update = (supplier: Supplier) => {
    (async () => {
      (await collection).doc(supplier.id).update({
        name: supplier.name,
      });
    })();
  };

  const remove = (id: string) => {
    (async () => {
      (await collection).doc(id).delete();
    })();
  };

  return (
    <SuppliersContext.Provider value={{ suppliers, add, update, remove }}>
      {children}
    </SuppliersContext.Provider>
  );
};
