import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { AuthRoutes } from "./AuthRoute";
import { PrivateRoute } from "./PrivateRoute";
import { MainPageRoutes } from "./MainRoute";
import { NavigationBar } from "../utils/NavigationBar";

export const AppRoutes = () => {
  return (
    <Router>
      <NavigationBar />
      <Switch>
        <PrivateRoute exact path="/main" component={MainPageRoutes} />
        <Route path="/auth" component={AuthRoutes} />
        <Redirect to="/main" from="/" />
      </Switch>
    </Router>
  );
};
