import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import { InputErrorText } from "../../utils/InputErrorText";
import { SuppliersContext } from "../../../contexts/suppliers";
import { modifyFullWidthIntoHalfWidth } from "../../../utils/modifyFullWidthIntoHalfWidth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
  })
);

export const AddSupplierFormDialog = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [isInvalidValue, setIsInvalidValue] = React.useState<boolean>(false);
  const [isDuplicated, setIsDuplicated] = React.useState<boolean>(false);
  const classes = useStyles();
  const { add, suppliers } = useContext(SuppliersContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsInvalidValue(false);
    setIsDuplicated(false);
    setName("");
  };

  const handleAddButtonClicked = () => {
    setIsInvalidValue(false);
    setIsDuplicated(false);
    const formattedName = modifyFullWidthIntoHalfWidth(name);

    if (!name) {
      setIsInvalidValue(true);
      return;
    }

    if (suppliers?.find((supplier) => supplier.name === formattedName)) {
      setIsDuplicated(true);
      return;
    }

    add && add({ name: formattedName });
    handleClose();
  };

  const [name, setName] = React.useState<string>("");
  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string);
  };

  return (
    <form>
      <IconButton
        aria-label="add"
        className={classes.button}
        color="primary"
        onClick={handleClickOpen}
      >
        <AddCircleIcon fontSize={"large"} />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">新規仕入先</DialogTitle>
        <DialogContent>
          <DialogContentText>新しい仕入先を追加する。</DialogContentText>
          <FormControl fullWidth className={classes.formControl} required>
            <TextField
              error={isInvalidValue}
              helperText={isInvalidValue && "入力は必須です"}
              id="supplier-name"
              label="仕入先名"
              value={name}
              onChange={handleNameChange}
              required
            />
            {isDuplicated && <InputErrorText message="同じ名前で既に登録されています" />}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            キャンセル
          </Button>
          <Button color="primary" onClick={handleAddButtonClicked}>
            登録
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
