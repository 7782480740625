import React, { useState, useMemo } from "react";
import { db } from "../Firebase";
import { Category } from "../types";

type CategoriesContextProps = {
  categories: Category[] | null;
  add: (category: Omit<Category, "id">) => void;
  update: (category: Category) => void;
  remove: (id: string) => void;
};

const buildCategory = (data: firebase.firestore.DocumentData) => {
  const category: Category = {
    id: data.id,
    name: data.name,
  };

  return category;
};

export const CategoriesContext = React.createContext<Partial<CategoriesContextProps>>({});

export const CategoriesProvider = ({ children }: any) => {
  const [categories, setCategories] = useState<Category[] | null | undefined>(undefined);

  const collection = useMemo(() => {
    const fetchData = async () => {
      const col = db.collection("categories");
      col.onSnapshot((query) => {
        const data: Category[] = [];
        query.forEach((d) => {
          const category = buildCategory({ ...d.data(), id: d.id });
          data.push(category);
        });
        setCategories(data);
      });
      return col;
    };
    return fetchData();
  }, []);

  const add = (category: Omit<Category, "id">) => {
    (async () => {
      (await collection).add({
        ...category,
      });
    })();
  };

  const update = (category: Category) => {
    (async () => {
      (await collection).doc(category.id).update({
        name: category.name,
      });
    })();
  };

  const remove = (id: string) => {
    (async () => {
      (await collection).doc(id).delete();
    })();
  };

  return (
    <CategoriesContext.Provider value={{ categories, add, update, remove }}>
      {children}
    </CategoriesContext.Provider>
  );
};
