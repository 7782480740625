import React, { useContext } from "react";
import { firestore } from "firebase";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { StocksContext } from "../../../contexts/stocks";
import { SuppliersContext } from "../../../contexts/suppliers";
import { CategoriesContext } from "../../../contexts/categories";
import { InputErrorText } from "../../utils/InputErrorText";
import { modifyFullWidthIntoHalfWidth } from "../../../utils/modifyFullWidthIntoHalfWidth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
  })
);

export const AddStockFormDialog: React.FC = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [isNameInvalid, setIsNameInvalid] = React.useState<boolean>(false);
  const [isCategoryInvalid, setIsCategoryInvalid] = React.useState<boolean>(false);
  const [isSupplierInvalid, setIsSupplierInvalid] = React.useState<boolean>(false);
  const [isPriceInvalid, setIsPriceInvalid] = React.useState<boolean>(false);
  const [isMinmumOrderQuantityInvalid, setIsMinimumOrderQuantityInvalid] = React.useState<boolean>(
    false
  );
  const [isAmountInvalid, setIsAmountInvalid] = React.useState<boolean>(false);
  const [isDuplicated, setIsDuplicated] = React.useState<boolean>(false);
  const classes = useStyles();
  const { categories } = useContext(CategoriesContext);
  const { suppliers } = useContext(SuppliersContext);
  const { add, stocks } = useContext(StocksContext);

  const resetErrorMessage = () => {
    setIsNameInvalid(false);
    setIsCategoryInvalid(false);
    setIsSupplierInvalid(false);
    setIsPriceInvalid(false);
    setIsMinimumOrderQuantityInvalid(false);
    setIsAmountInvalid(false);
    setIsDuplicated(false);
  };

  const setStateValues = () => {
    setName("");
    setSupplier("");
    setCategory("");
    setAmount(0);
    setPrice(0);
    setMinimumOrderQuantity(0);
    resetErrorMessage();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setStateValues();
  };

  const handleAddButtonClicked = () => {
    resetErrorMessage();
    const formattedName = modifyFullWidthIntoHalfWidth(name);

    if (!name) {
      setIsNameInvalid(true);
    }

    if ((price as unknown) === "") {
      setIsPriceInvalid(true);
    }

    if ((amount as unknown) === "") {
      setIsAmountInvalid(true);
    }

    if (!category) {
      setIsCategoryInvalid(true);
    }

    if (!supplier) {
      setIsSupplierInvalid(true);
    }

    if ((minimumOrderQuantity as unknown) === "") {
      setIsMinimumOrderQuantityInvalid(true);
    }

    if (stocks?.find((stock) => stock.name === formattedName && stock.supplier === supplier)) {
      setIsDuplicated(true);
    }

    if (
      add &&
      name &&
      suppliers &&
      categories &&
      supplier &&
      category &&
      (price as unknown) !== "" &&
      (amount as unknown) !== "" &&
      (minimumOrderQuantity as unknown) !== "" &&
      !stocks?.find((stock) => stock.name === formattedName && stock.supplier === supplier)
    ) {
      add({
        name: formattedName,
        amount: +amount,
        minimumOrderQuantity: +minimumOrderQuantity,
        price: +price,
        supplier,
        supplierId: suppliers.find((el) => el.name === supplier)!.id,
        category,
        categoryId: categories.find((el) => el.name === category)!.id,
        stockInDates: [firestore.Timestamp.now()],
      });
      handleClose();
    }
  };

  const [name, setName] = React.useState<string>("");
  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string);
  };

  const [supplier, setSupplier] = React.useState<string>("");
  const handleSupplierChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSupplier(event.target.value as string);
  };

  const [category, setCategory] = React.useState<string>("");
  const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategory(event.target.value as string);
  };

  const [price, setPrice] = React.useState<number>(0);
  const handlePriceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPrice(event.target.value as number);
  };

  const [amount, setAmount] = React.useState<number>(0);
  const handleAmountChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAmount(event.target.value as number);
  };

  const [minimumOrderQuantity, setMinimumOrderQuantity] = React.useState<number>(0);
  const handleMinimumOrderQuantityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMinimumOrderQuantity(event.target.value as number);
  };

  return (
    <form>
      <IconButton
        aria-label="add"
        className={classes.button}
        color="primary"
        onClick={handleClickOpen}
      >
        <AddCircleIcon fontSize={"large"} />
      </IconButton>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">新規品目登録</DialogTitle>
        <DialogContent>
          <DialogContentText>新しい品目を追加する。</DialogContentText>
          <FormControl fullWidth className={classes.formControl} required>
            <TextField
              id="stock-name"
              label="品目名"
              value={name}
              onChange={handleNameChange}
              error={isNameInvalid}
              required
              helperText={isNameInvalid && "入力は必須です"}
            />
            {isDuplicated && <InputErrorText message="同じ名前と仕入先で既に登録されています" />}
          </FormControl>
          <FormControl fullWidth className={classes.formControl} required>
            <InputLabel id="supplier-select-label">仕入先</InputLabel>
            <Select
              labelId="supplier-select-label"
              id="supplier-select"
              onChange={handleSupplierChange}
              value={supplier}
              error={isSupplierInvalid}
            >
              {suppliers &&
                suppliers
                  .sort((a, b) => a.name.localeCompare(b.name, "ja"))
                  .map((el, i) => (
                    <MenuItem value={el.name} key={i}>
                      {el.name}
                    </MenuItem>
                  ))}
            </Select>
            {isSupplierInvalid && <InputErrorText message="入力は必須です" />}
          </FormControl>

          <FormControl fullWidth className={classes.formControl} required>
            <InputLabel id="category-select-label">分類</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              onChange={handleCategoryChange}
              value={category}
              error={isCategoryInvalid}
            >
              {categories &&
                categories
                  .sort((a, b) => a.name.localeCompare(b.name, "ja"))
                  .map((el, i) => (
                    <MenuItem value={el.name} key={i}>
                      {el.name}
                    </MenuItem>
                  ))}
            </Select>
            {isCategoryInvalid && <InputErrorText message="入力は必須です" />}
          </FormControl>

          <FormControl fullWidth className={classes.formControl} required>
            <InputLabel htmlFor="price">単価</InputLabel>
            <Input
              id="price"
              type={"number"}
              value={price}
              onChange={handlePriceChange}
              startAdornment={<InputAdornment position="start">¥</InputAdornment>}
              error={isPriceInvalid}
            />
            {isPriceInvalid && <InputErrorText message="入力は必須です" />}
          </FormControl>

          <FormControl fullWidth className={classes.formControl} required>
            <InputLabel htmlFor="price">最小発注量</InputLabel>
            <Input
              id="minimumOrderQuantity"
              type={"number"}
              value={minimumOrderQuantity}
              onChange={handleMinimumOrderQuantityChange}
              error={isMinmumOrderQuantityInvalid}
            />
            {isMinmumOrderQuantityInvalid && <InputErrorText message="入力は必須です" />}
          </FormControl>

          <FormControl fullWidth className={classes.formControl} required>
            <InputLabel htmlFor="standard-adornment-amount">在個数</InputLabel>
            <Input
              id="amount"
              type={"number"}
              value={amount}
              onChange={handleAmountChange}
              error={isAmountInvalid}
            />
            {isAmountInvalid && <InputErrorText message="入力は必須です" />}
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleAddButtonClicked} color="primary">
            登録
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
