import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { AuthContext } from "../../contexts/auth";
import { LoadingOverlay } from "../utils/LoadingOverlay";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const SignInPage: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();

  const { signin, loadingAuthState, didSomeErrorHappended, didSigninFailed } = useContext(
    AuthContext
  );

  const [email, setEmail] = React.useState("");
  const handleEmailChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEmail(event.target.value as string);
  };

  const [password, setPassword] = React.useState("");
  const handlePasswordChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPassword(event.target.value as string);
  };

  const resetState = () => {
    setEmail("");
    setPassword("");
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    signin && signin(email, password, resetState, props.history);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <LoadingOverlay isLoading={loadingAuthState === true} />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          サインイン
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          {didSigninFailed === true && <p>メールまたはパスワードが違います</p>}
          {didSomeErrorHappended === true && <p>エラーが発生しました</p>}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={handlePasswordChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            サインイン
          </Button>
        </form>
      </div>
    </Container>
  );
};
