import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router";
import { AuthContext } from "../../contexts/auth";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    signOutButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      cursor: "pointer",
    },
  })
);

export const Component: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const { signout, currentUser } = useContext(AuthContext);

  const handleSignOutButtonClick = () => {
    signout && signout();
  };

  const handleLinkClicked = () => {
    props.history.push("/main");
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h5" color="inherit" className={classes.title} align="left">
            <Link color="inherit" onClick={handleLinkClicked} underline={"none"}>
              在庫管理
            </Link>
          </Typography>
          {currentUser && (
            <Button
              onClick={handleSignOutButtonClick}
              color="inherit"
              className={classes.signOutButton}
            >
              サインアウト
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export const NavigationBar = withRouter(Component);
