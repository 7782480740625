import React from "react";
import { Route, Switch } from "react-router-dom";
import { MainPage } from "../pages/MainPage";

export const MainPageRoutes = () => {
  return (
    <Switch>
      <Route exact path="/main" component={MainPage} />
    </Switch>
  );
};
