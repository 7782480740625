import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

type ListMenuProps = {
  buttonText: string;
  menuItems: string[];
  menuTitle: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuTitle: {
      fontWeight: "bold",
    },
  })
);

export const ListMenu: React.FC<ListMenuProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { buttonText, menuItems, menuTitle } = props;

  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button aria-controls="List-menu" aria-haspopup="true" onClick={handleClick} color="primary">
        {buttonText}
      </Button>

      <Menu
        id="list-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <Typography className={classes.menuTitle}>{menuTitle}</Typography>
        </MenuItem>
        {menuItems.map((el, i) => (
          <MenuItem key={i}>
            {i + 1}: {el}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
