import React, { useState, useMemo } from "react";
import { db } from "../Firebase";
import { Stock } from "../types";

type StocksContextProps = {
  stocks: Stock[] | null;
  add: (stock: Omit<Stock, "id">) => void;
  update: (stock: Stock) => void;
  remove: (id: string) => void;
};

const buildStock = (data: firebase.firestore.DocumentData) => {
  const stock: Stock = {
    id: data.id,
    name: data.name,
    price: data.price,
    supplier: data.supplier,
    supplierId: data.supplierId,
    category: data.category,
    categoryId: data.categoryId,
    amount: data.amount,
    minimumOrderQuantity: data.minimumOrderQuantity,
    stockInDates: data.stockInDates,
  };

  return stock;
};

export const StocksContext = React.createContext<Partial<StocksContextProps>>({});

export const StocksProvider = ({ children }: any) => {
  const [stocks, setStocks] = useState<Stock[] | null | undefined>(undefined);

  const collection = useMemo(() => {
    const fetchData = () => {
      const col = db.collection("stocks");
      col.onSnapshot((query) => {
        const data: Stock[] = [];
        query.forEach((d) => {
          const stock = buildStock({ ...d.data(), id: d.id });
          data.push(stock);
        });
        setStocks(data);
      });
      return col;
    };
    return fetchData();
  }, []);

  const add = (stock: Omit<Stock, "id">) => {
    (async () => {
      await collection.add({
        name: stock.name,
        price: stock.price,
        supplier: stock.supplier,
        supplierId: stock.supplierId,
        category: stock.category,
        categoryId: stock.categoryId,
        amount: stock.amount,
        minimumOrderQuantity: stock.minimumOrderQuantity,
        stockInDates: stock.stockInDates,
      });
    })();
  };

  const update = (stock: Stock) => {
    (async () => {
      await collection.doc(stock.id).update({
        name: stock.name,
        price: stock.price,
        supplier: stock.supplier,
        supplierId: stock.supplierId,
        category: stock.category,
        categoryId: stock.categoryId,
        amount: stock.amount,
        minimumOrderQuantity: stock.minimumOrderQuantity,
        stockInDates: stock.stockInDates,
      });
    })();
  };

  const remove = (id: string) => {
    (async () => {
      await collection.doc(id).delete();
    })();
  };

  return (
    <StocksContext.Provider value={{ stocks, add, update, remove }}>
      {children}
    </StocksContext.Provider>
  );
};
