import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";

type InputErrorTextProps = {
  message: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorText: {
      color: "red",
    },
  })
);

export const InputErrorText: React.FC<InputErrorTextProps> = (props) => {
  const classes = useStyles();
  return <FormHelperText className={classes.errorText}>{props.message}</FormHelperText>;
};
