import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { CategoriesContext } from "../../contexts/categories";
import { AddCategoryFormDialog } from "../dialogs/category/AddCategoryFormDialog";
import { EditCategoryFormDialog } from "../dialogs/category/EditCategoryFormDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    table: {
      margin: "0 auto",
      maxWidth: 650,
    },
    tableHeader: {
      fontWeight: "bold",
    },
  })
);

export const CategoryTabPanel: React.FC = () => {
  const classes = useStyles();
  const { categories } = useContext(CategoriesContext);

  return (
    <div>
      <TableContainer component={Paper} className={classes.table}>
        <Table aria-label="category-table">
          <TableHead className={classes.table}>
            <TableRow>
              <TableCell className={classes.tableHeader}>分類</TableCell>
              <TableCell className={classes.tableHeader} align={"right"}>
                <AddCategoryFormDialog />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories &&
              categories
                .sort((a, b) => a.name.localeCompare(b.name, "ja"))
                .map((category, i) => (
                  <TableRow key={i}>
                    <TableCell>{category.name}</TableCell>
                    <TableCell align={"right"}>
                      <EditCategoryFormDialog category={category} />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
