import React, { useContext, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import { SuppliersContext } from "../../../contexts/suppliers";
import { Supplier } from "../../../types";
import { DeleteSupplierFormDialog } from "./DeleteSupplierFormDialog";
import { InputErrorText } from "../../utils/InputErrorText";
import { modifyFullWidthIntoHalfWidth } from "../../../utils/modifyFullWidthIntoHalfWidth";

type EditSupplierFormDialogProps = {
  supplier: Supplier;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
  })
);

export const EditSupplierFormDialog: React.FC<EditSupplierFormDialogProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [isInvalidValue, setIsInvalidValue] = React.useState<boolean>(false);
  const [isDuplicated, setIsDuplicated] = React.useState<boolean>(false);
  const classes = useStyles();
  const { update, suppliers } = useContext(SuppliersContext);

  const [name, setName] = React.useState<string>("");
  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string);
  };

  useEffect(() => {
    setName(props.supplier.name);
  }, [props.supplier]);

  const handleClickOpen = async () => {
    setName(props.supplier.name);
    setOpen(true);
  };

  const handleClose = () => {
    setIsInvalidValue(false);
    setIsDuplicated(false);
    setOpen(false);
  };

  const handleUpdateButtonClicked = () => {
    setIsInvalidValue(false);
    setIsDuplicated(false);
    const formattedName = modifyFullWidthIntoHalfWidth(name);

    if (!name) {
      setIsInvalidValue(true);
      return;
    }

    if (
      suppliers?.find(
        (supplier) => supplier.name === formattedName && props.supplier.name !== formattedName
      )
    ) {
      setIsDuplicated(true);
      return;
    }

    update && update({ id: props.supplier.id, name: formattedName });
    handleClose();
  };

  return (
    <form>
      <IconButton
        aria-label="add"
        className={classes.button}
        color="primary"
        onClick={handleClickOpen}
      >
        <EditIcon fontSize={"large"} />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        style={{ minWidth: "1000px" }}
      >
        <DialogTitle id="form-dialog-title">仕入先編集</DialogTitle>

        <DialogContent>
          <DialogContentText>編集する</DialogContentText>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              error={isInvalidValue}
              helperText={isInvalidValue && "入力は必須です"}
              id="supplier-name"
              label="仕入先名"
              value={name}
              onChange={handleNameChange}
            />
          </FormControl>
          {isDuplicated && <InputErrorText message="同じ名前で既に登録されています" />}
          <DeleteSupplierFormDialog supplierId={props.supplier.id} handleEditDialog={handleClose} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleUpdateButtonClicked} color="primary">
            更新
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
