import React, { useContext, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { AddStockFormDialog } from "../dialogs/stock/AddStockFormDialog";
import { EditStockFormDialog } from "../dialogs/stock/EditStockFormDialog";
import { ListMenu } from "../utils/ListMenu";
import { StocksContext } from "../../contexts/stocks";
import { SuppliersContext } from "../../contexts/suppliers";
import { CategoriesContext } from "../../contexts/categories";
import { dateToString } from "../../utils/dateToString";
import { AddOrderFormDialog } from "../dialogs/order/AddOrderFormDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },

    searchTextField: {
      minWidth: 300,
    },
    button: {
      margin: theme.spacing(1),
    },
    table: {
      minWidth: 650,
    },
    tableHeader: {
      fontWeight: "bold",
    },
  })
);

export const StockTabPanel: React.FC = () => {
  const classes = useStyles();

  const [name, setName] = useState<string>("");
  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string);
  };

  const { suppliers } = useContext(SuppliersContext);
  const [supplier, setSupplier] = React.useState("all");
  const handleSupplierChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSupplier(event.target.value as string);
  };

  const { categories } = useContext(CategoriesContext);
  const [category, setCategory] = React.useState("all");
  const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategory(event.target.value as string);
  };

  const { stocks } = useContext(StocksContext);

  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
  const isSelectingAll = !name && supplier === "all" && category === "all";

  return (
    <div>
      <form onSubmit={preventDefault}>
        <FormControl className={classes.formControl}>
          <TextField
            id="standard-basic"
            label="品目名"
            className={classes.searchTextField}
            value={name}
            onChange={handleNameChange}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="supplier-select-label">仕入先</InputLabel>
          <Select
            labelId="supplier-select-label"
            id="supplier-select"
            value={supplier}
            onChange={handleSupplierChange}
          >
            <MenuItem value={"all"}>全て</MenuItem>
            {suppliers &&
              suppliers
                .sort((a, b) => a.name.localeCompare(b.name, "ja"))
                .map((el, i) => (
                  <MenuItem value={el.name} key={i}>
                    {el.name}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="category-select-label">分類</InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={category}
            onChange={handleCategoryChange}
          >
            <MenuItem value={"all"}>全て</MenuItem>
            {categories &&
              categories
                .sort((a, b) => a.name.localeCompare(b.name, "ja"))
                .map((el, i) => (
                  <MenuItem value={el.name} key={i}>
                    {el.name}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        <IconButton aria-label="search" className={classes.button} disabled>
          <SearchIcon fontSize="large" />
        </IconButton>
      </form>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="stock-table">
          <TableHead>
            <TableRow className={classes.table}>
              <TableCell className={classes.tableHeader}>品目名</TableCell>
              <TableCell align="left" className={classes.tableHeader}>
                仕入先
              </TableCell>
              <TableCell align="left" className={classes.tableHeader}>
                分類
              </TableCell>
              <TableCell align="right" className={classes.tableHeader}>
                単価
              </TableCell>
              <TableCell align="right" className={classes.tableHeader}>
                最小発注量
              </TableCell>
              <TableCell align="right" className={classes.tableHeader}>
                在庫数
              </TableCell>
              <TableCell align="center" className={classes.tableHeader}>
                最終仕入れ日
              </TableCell>
              <TableCell align="center">
                <AddStockFormDialog />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stocks &&
              !isSelectingAll &&
              stocks
                .filter((stock) => {
                  if (!category || category === "all") {
                    return true;
                  } else {
                    return stock.category === category;
                  }
                })
                .filter((stock) => {
                  if (!supplier || supplier === "all") {
                    return true;
                  } else {
                    return stock.supplier === supplier;
                  }
                })
                .filter((stock) => {
                  if (!name) {
                    return true;
                  } else {
                    return stock.name.includes(name);
                  }
                })
                .sort((a, b) => a.name.localeCompare(b.name, "ja"))
                .map((stock, i) => (
                  <TableRow key={i}>
                    <TableCell>{stock.name}</TableCell>
                    <TableCell align="left">{stock.supplier}</TableCell>
                    <TableCell align="left">{stock.category}</TableCell>
                    <TableCell align="right">{stock.price}</TableCell>
                    <TableCell align="right">{stock.minimumOrderQuantity}</TableCell>
                    <TableCell align="right">{stock.amount}</TableCell>
                    <TableCell align="center">
                      <ListMenu
                        buttonText={dateToString(
                          stock.stockInDates.sort((a, b) => (a < b ? 1 : -1))[0].toDate()
                        )}
                        menuItems={stock.stockInDates
                          .sort((a, b) => (a < b ? 1 : -1))
                          .map((date) => dateToString(date.toDate()))}
                        menuTitle={"仕入れ日履歴"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <EditStockFormDialog stock={stock} />
                      <AddOrderFormDialog stock={stock} />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
