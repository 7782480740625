import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { SuppliersContext } from "../../contexts/suppliers";
import { AddSupplierFormDialog } from "../dialogs/supplier/AddSupplierFormDialog";
import { EditSupplierFormDialog } from "../dialogs/supplier/EditSupplierFormDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    table: {
      margin: "0 auto",
      maxWidth: 650,
    },
    tableHeader: {
      fontWeight: "bold",
    },
  })
);

export const SupplierTabPanel: React.FC = () => {
  const classes = useStyles();
  const { suppliers } = useContext(SuppliersContext);

  return (
    <div>
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="suppler table">
          <TableHead className={classes.table}>
            <TableRow>
              <TableCell className={classes.tableHeader}>仕入先</TableCell>
              <TableCell className={classes.tableHeader} align={"right"}>
                <AddSupplierFormDialog />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suppliers &&
              suppliers
                .sort((a, b) => a.name.localeCompare(b.name, "ja"))
                .map((supplier, i) => (
                  <TableRow key={i}>
                    <TableCell>{supplier.name}</TableCell>
                    <TableCell align={"right"}>
                      <EditSupplierFormDialog supplier={supplier} />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
