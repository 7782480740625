import React, { useState, useMemo } from "react";
import { db } from "../Firebase";
import { Order, OrderStatus } from "../types";
import { firestore } from "firebase";

type OrdersContextProps = {
  orders: Order[] | null;
  add: (order: Omit<Order, "createdAt">) => void;
  updateStatus: (id: string, status: OrderStatus) => void;
  remove: (id: string) => void;
};

const buildOrder = (data: firebase.firestore.DocumentData) => {
  const order: Order = {
    id: data.id,
    item: data.item,
    stockId: data.stockId,
    quantity: data.quantity,
    supplier: data.supplier,
    status: data.status,
    createdAt: data.createdAt,
  };

  return order;
};

export const OrdersContext = React.createContext<Partial<OrdersContextProps>>({});

export const OrdersProvider = ({ children }: any) => {
  const [orders, setOrders] = useState<Order[] | null | undefined>(undefined);

  const collection = useMemo(() => {
    const fetchData = async () => {
      const col = db.collection("orders");

      col.onSnapshot((query) => {
        const data: Order[] = [];
        query.forEach((d) => {
          const order = buildOrder({ ...d.data(), id: d.id });
          data.push(order);
        });
        setOrders(data);
      });
      return col;
    };
    return fetchData();
  }, []);

  const add = (order: Omit<Order, "createdAt">) => {
    (async () => {
      (await collection).add({
        item: order.item,
        stockId: order.stockId,
        supplier: order.supplier,
        quantity: order.quantity,
        status: order.status,
        createdAt: firestore.FieldValue.serverTimestamp(),
      });
    })();
  };

  const updateStatus = (id: string, status: OrderStatus) => {
    (async () => {
      (await collection).doc(id).update({
        status,
      });
    })();
  };

  const remove = (id: string) => {
    (async () => {
      (await collection).doc(id).delete();
    })();
  };

  return (
    <OrdersContext.Provider value={{ orders, add, updateStatus, remove }}>
      {children}
    </OrdersContext.Provider>
  );
};
