import React, { useContext, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { OrdersContext } from "../../../contexts/orders";
import { Order, OrderStatus } from "../../../types";
import { DeleteOrderFormDialog } from "./DeleteOrderFormDialog";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

type EditOrderStatusFormDialogProps = {
  order: Order;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
  })
);

const statusSelectValues: OrderStatus[] = [
  OrderStatus.Registered,
  OrderStatus.Ordered,
  OrderStatus.Delivered,
];

export const EditOrderStatusFormDialog: React.FC<EditOrderStatusFormDialogProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { updateStatus } = useContext(OrdersContext);

  const [status, setStatus] = React.useState<OrderStatus>();
  const handleStatusSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as OrderStatus);
  };

  useEffect(() => {
    setStatus(props.order.status);
  }, [props.order]);

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateButtonClicked = () => {
    if (!status) return;
    updateStatus && updateStatus(props.order.id, status);
    handleClose();
  };

  return (
    <form>
      <IconButton
        aria-label="add"
        className={classes.button}
        color="primary"
        onClick={handleClickOpen}
      >
        <EditIcon fontSize={"large"} />
      </IconButton>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">ステータス更新</DialogTitle>

        <DialogContent>
          <DialogContentText>ステータスを更新する</DialogContentText>
          <FormControl fullWidth className={classes.formControl}>
            <Select
              value={status}
              onChange={handleStatusSelectChange}
              labelId="statu-select-label"
              id="status-select"
            >
              {statusSelectValues.map((el, i) => (
                <MenuItem value={el} key={i}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DeleteOrderFormDialog orderId={props.order.id} handleEditDialog={handleClose} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleUpdateButtonClicked} color="primary">
            更新
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
