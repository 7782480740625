import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import { LoadingOverlay } from "../utils/LoadingOverlay";

export const PrivateRoute = ({ component: RouteComponent, ...rest }: any) => {
  const { currentUser, loadingAuthState } = useContext(AuthContext);
  if (loadingAuthState) {
    return <LoadingOverlay isLoading={true} />;
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        currentUser ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={{ pathname: "/auth/signin", state: { prevPath: rest.path } }} />
        )
      }
    />
  );
};
