import React, { useContext, useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { Stock } from "../../types";
import PrintIcon from "@material-ui/icons/Print";
import { StocksContext } from "../../contexts/stocks";
import { SuppliersContext } from "../../contexts/suppliers";
import { CategoriesContext } from "../../contexts/categories";
import { exportPDF } from "../../utils/exportPDF";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },

    searchTextField: {
      minWidth: 300,
    },
    button: {
      margin: theme.spacing(1),
    },
    table: {
      minWidth: 650,
    },
    tableHeader: {
      fontWeight: "bold",
    },
  })
);

export const InventoryTabPanel: React.FC = () => {
  const classes = useStyles();

  const [name, setName] = useState<string>("");
  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string);
  };

  const { suppliers } = useContext(SuppliersContext);
  const [supplier, setSupplier] = React.useState("all");
  const handleSupplierChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSupplier(event.target.value as string);
  };

  const { categories } = useContext(CategoriesContext);
  const [category, setCategory] = React.useState("all");
  const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategory(event.target.value as string);
  };

  const { stocks } = useContext(StocksContext);
  const [stocksToRender, setStocksToRender] = React.useState<Stock[]>([]);

  const handlePrintButtonClicked = () => {
    exportPDF(
      "inventory",

      Math.round(
        stocksToRender.map((stock) => stock.price * stock.amount).reduce((a, x) => (a += x), 0) *
          100
      ) / 100
    );
  };

  useEffect(() => {
    if (stocks) {
      setStocksToRender(
        stocks
          .filter((stock) => {
            if (!category || category === "all") {
              return true;
            } else {
              return stock.category === category;
            }
          })
          .filter((stock) => {
            if (!supplier || supplier === "all") {
              return true;
            } else {
              return stock.supplier === supplier;
            }
          })
          .filter((stock) => {
            if (!name) {
              return true;
            } else {
              return stock.name.includes(name);
            }
          })
          .sort((a, b) => a.name.localeCompare(b.name, "ja"))
      );
    }
  }, [category, name, stocks, supplier]);

  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

  return (
    <div>
      <form onSubmit={preventDefault}>
        <FormControl className={classes.formControl}>
          <TextField
            id="standard-basic"
            label="品目名"
            className={classes.searchTextField}
            value={name}
            onChange={handleNameChange}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="supplier-select-label">仕入先</InputLabel>
          <Select
            labelId="supplier-select-label"
            id="supplier-select"
            value={supplier}
            onChange={handleSupplierChange}
          >
            <MenuItem value={"all"}>全て</MenuItem>
            {suppliers &&
              suppliers
                .sort((a, b) => a.name.localeCompare(b.name, "ja"))
                .map((el, i) => (
                  <MenuItem value={el.name} key={i}>
                    {el.name}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="category-select-label">分類</InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={category}
            onChange={handleCategoryChange}
          >
            <MenuItem value={"all"}>全て</MenuItem>
            {categories &&
              categories
                .sort((a, b) => a.name.localeCompare(b.name, "ja"))
                .map((el, i) => (
                  <MenuItem value={el.name} key={i}>
                    {el.name}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        <IconButton aria-label="search" className={classes.button} disabled>
          <SearchIcon fontSize="large" />
        </IconButton>
        <IconButton
          aria-label="add"
          className={classes.button}
          color="primary"
          onClick={handlePrintButtonClicked}
        >
          <PrintIcon fontSize={"large"} />
        </IconButton>
      </form>
      <Typography align="right" variant="h6">
        総額： ¥
        {Math.round(
          stocksToRender.map((stock) => stock.price * stock.amount).reduce((a, x) => (a += x), 0) *
            100
        ) / 100}
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="stock-table" id="inventory">
          <TableHead>
            <TableRow className={classes.table}>
              <TableCell align="right" className={classes.tableHeader}></TableCell>
              <TableCell className={classes.tableHeader}>品目名</TableCell>
              <TableCell align="left" className={classes.tableHeader}>
                分類
              </TableCell>
              <TableCell align="right" className={classes.tableHeader}>
                単価
              </TableCell>
              <TableCell align="right" className={classes.tableHeader}>
                在庫数
              </TableCell>
              <TableCell align="right" className={classes.tableHeader}>
                合計額
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stocksToRender &&
              stocksToRender
                .sort((a, b) => a.name.localeCompare(b.name, "ja"))
                .map((stock, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">{i + 1}</TableCell>
                    <TableCell>{stock.name}</TableCell>
                    <TableCell align="left">{stock.category}</TableCell>
                    <TableCell align="right">{stock.price}</TableCell>
                    <TableCell align="right">{stock.amount}</TableCell>
                    <TableCell align="right">
                      {Math.round(stock.price * stock.amount * 100) / 100}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
