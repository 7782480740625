import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import { StocksContext } from "../../../contexts/stocks";

type DeleteStockFormDialogProps = {
  stockId: string;
  handleEditDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
  })
);

export const DeleteStockFormDialog: React.FC<DeleteStockFormDialogProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const { handleEditDialog } = props;
  const classes = useStyles();

  const { remove } = useContext(StocksContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDeleteButtonClicked = () => {
    remove && remove(props.stockId);
    setOpen(false);
    handleEditDialog(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<DeleteIcon />}
        onClick={handleClickOpen}
        className={classes.button}
        size={"small"}
      >
        削除
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"このデータを本当に削除していいですか？"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            キャンセル
          </Button>
          <Button onClick={handleDeleteButtonClicked} color="secondary">
            削除する
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
