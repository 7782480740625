import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { StockTabPanel } from "../panels/StockTabPanel";
import { SupplierTabPanel } from "../panels/SupplierTabPanel";
import { CategoryTabPanel } from "../panels/CategoryTabPanel";
import { OrderTabPanel } from "../panels/OrderTabPanel";
import { InventoryTabPanel } from "../panels/InventoryTabPanel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Container maxWidth="lg">{children}</Container>
        </Box>
      )}
    </div>
  );
};

export const MainPage: React.FC = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="在庫" />
          <Tab label="発注履歴" />
          <Tab label="仕入先" />
          <Tab label="分類" />
          <Tab label="棚卸し" />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <StockTabPanel />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OrderTabPanel />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SupplierTabPanel />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CategoryTabPanel />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <InventoryTabPanel />
      </TabPanel>
    </div>
  );
};
