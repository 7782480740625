import React from "react";
import "./App.css";
import "../components/utils/NavigationBar";
import { StocksProvider } from "../contexts/stocks";
import { AuthProvider } from "../contexts/auth";
import { SuppliersProvider } from "../contexts/suppliers";
import { CategoriesProvider } from "../contexts/categories";
import { OrdersProvider } from "../contexts/orders";
import { AppRoutes } from "../components/routes/AppRoutes";

const App = () => {
  return (
    <AuthProvider>
      <StocksProvider>
        <OrdersProvider>
          <CategoriesProvider>
            <SuppliersProvider>
              <div className="App">
                <AppRoutes />
              </div>
            </SuppliersProvider>
          </CategoriesProvider>
        </OrdersProvider>
      </StocksProvider>
    </AuthProvider>
  );
};

export default App;
