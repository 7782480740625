import React, { useContext } from "react";
import { firestore } from "firebase";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Container } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import { DeleteStockFormDialog } from "./DeleteStockFormDialog";
import { StocksContext } from "../../../contexts/stocks";
import { SuppliersContext } from "../../../contexts/suppliers";
import { CategoriesContext } from "../../../contexts/categories";
import { Stock } from "../../../types";
import { InputErrorText } from "../../utils/InputErrorText";
import { modifyFullWidthIntoHalfWidth } from "../../../utils/modifyFullWidthIntoHalfWidth";

type EditStockFormDialogProps = {
  stock: Stock;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
  })
);

export const EditStockFormDialog: React.FC<EditStockFormDialogProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  const [openDetail, setOpenDetail] = React.useState(false);
  const [isInputTypeConsume, setIsInputTypeConsume] = React.useState(true);
  const [isNameInvalid, setIsNameInvalid] = React.useState<boolean>(false);
  const [isPriceInvalid, setIsPriceInvalid] = React.useState<boolean>(false);
  const [isMinmumOrderQuantityInvalid, setIsMinimumOrderQuantityInvalid] = React.useState<boolean>(
    false
  );
  const [isAmountInvalid, setIsAmountInvalid] = React.useState<boolean>(false);
  const [isAmountToEditInvalid, setIsAmountToEditInvalid] = React.useState<boolean>(false);
  const [isDuplicated, setIsDuplicated] = React.useState<boolean>(false);

  const resetErrorMessage = () => {
    setIsNameInvalid(false);
    setIsPriceInvalid(false);
    setIsMinimumOrderQuantityInvalid(false);
    setIsAmountInvalid(false);
    setIsAmountToEditInvalid(false);
    setIsDuplicated(false);
  };

  const setStateValues = () => {
    setName(props.stock.name);
    setSupplier(props.stock.supplier);
    setCategory(props.stock.category);
    setAmount(props.stock.amount);
    setPrice(props.stock.price);
    setIsInputTypeConsume(true);
    setOpenDetail(false);
    setAmountToEdit(0);
    setMinimumOrderQuantity(props.stock.minimumOrderQuantity);
    resetErrorMessage();
  };

  const { categories } = useContext(CategoriesContext);
  const { suppliers } = useContext(SuppliersContext);
  const { update, stocks } = useContext(StocksContext);

  const [name, setName] = React.useState<string>("");
  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string);
  };

  const [supplier, setSupplier] = React.useState<string>("");
  const handleSupplierChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSupplier(event.target.value as string);
  };

  const [category, setCategory] = React.useState<string>("");
  const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategory(event.target.value as string);
  };

  const [price, setPrice] = React.useState<number | undefined>(0);
  const handlePriceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPrice(event.target.value as number);
  };

  const [amount, setAmount] = React.useState<number>(0);
  const handleAmountChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAmount(event.target.value as number);
  };

  const [amountToEdit, setAmountToEdit] = React.useState<number>(0);
  const handleAmountToEditChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAmountToEdit(event.target.value as number);
  };

  const [minimumOrderQuantity, setMinimumOrderQuantity] = React.useState<number>(0);
  const handleMinimumOrderQuantityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMinimumOrderQuantity(event.target.value as number);
  };

  const handleClickOpen = async () => {
    setStateValues();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleOpenDetail = () => {
    setOpenDetail(!openDetail);
  };

  const toggleAmountInputTyoe = () => {
    setIsInputTypeConsume(!isInputTypeConsume);
  };

  const handleUpdateButtonClicked = () => {
    resetErrorMessage();
    const formattedName = modifyFullWidthIntoHalfWidth(name);

    if (!name) {
      setIsNameInvalid(true);
    }

    if ((price as unknown) === "") {
      setIsPriceInvalid(true);
    }

    if ((amount as unknown) === "") {
      setIsAmountInvalid(true);
    }

    if ((amountToEdit as unknown) === "") {
      setIsAmountToEditInvalid(true);
    }

    if ((minimumOrderQuantity as unknown) === "") {
      setIsMinimumOrderQuantityInvalid(true);
    }

    if (
      stocks?.find(
        (stock) =>
          stock.name === formattedName && stock.supplier === supplier && stock.id !== props.stock.id
      )
    ) {
      setIsDuplicated(true);
    }

    console.log(
      stocks?.find(
        (stock) =>
          stock.name === formattedName && stock.supplier === supplier && stock.id !== props.stock.id
      )
    );

    if (
      suppliers &&
      categories &&
      update &&
      name &&
      (price as unknown) !== "" &&
      (amount as unknown) !== "" &&
      (amountToEdit as unknown) !== "" &&
      (minimumOrderQuantity as unknown) !== "" &&
      !stocks?.find(
        (stock) =>
          stock.name === formattedName && stock.supplier === supplier && stock.id !== props.stock.id
      )
    ) {
      update({
        id: props.stock.id,
        name: formattedName,
        price: +price!,
        amount: isInputTypeConsume ? +amount - +amountToEdit : +amount + +amountToEdit,
        minimumOrderQuantity: +minimumOrderQuantity,
        supplier,
        supplierId: suppliers.find((el) => el.name === supplier)!.id,
        category,
        categoryId: categories.find((el) => el.name === category)!.id,
        stockInDates: isInputTypeConsume
          ? [...props.stock.stockInDates]
          : [...props.stock.stockInDates, firestore.Timestamp.now()],
      });
      handleClose();
    }
  };

  return (
    <form>
      <IconButton
        aria-label="add"
        className={classes.button}
        color="primary"
        onClick={handleClickOpen}
      >
        <EditIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        style={{ minWidth: "1000px" }}
      >
        <DialogTitle id="form-dialog-title">品目編集</DialogTitle>

        <DialogContent>
          <DialogContentText>編集する</DialogContentText>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="stock-name"
              label="品目名"
              value={name}
              onChange={handleNameChange}
              error={isNameInvalid}
              helperText={isNameInvalid && "入力は必須です"}
            />
            {isDuplicated && <InputErrorText message="同じ名前と仕入先で既に登録されています" />}
          </FormControl>
          <Grid container spacing={1} alignItems="baseline">
            <Grid xs={4} item={true}>
              <FormControl className={classes.formControl}>
                <InputLabel>現在個数</InputLabel>
                <Input
                  id="amount"
                  type={"number"}
                  value={amount}
                  onChange={handleAmountChange}
                  error={isAmountInvalid}
                />
                {isAmountInvalid && <InputErrorText message="入力は必須です" />}
              </FormControl>
            </Grid>

            <Grid xs={4} item={true}>
              <FormControl className={classes.formControl}>
                {isInputTypeConsume ? <InputLabel>消費</InputLabel> : <InputLabel>追加</InputLabel>}
                <Input
                  id="amount-to-Edit"
                  type={"number"}
                  value={amountToEdit}
                  onChange={handleAmountToEditChange}
                  error={isAmountToEditInvalid}
                  startAdornment={
                    isInputTypeConsume ? (
                      <InputAdornment position="start">
                        <RemoveIcon />
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="start">
                        <AddIcon />
                      </InputAdornment>
                    )
                  }
                />
                {isAmountToEditInvalid && <InputErrorText message="入力は必須です" />}
              </FormControl>
            </Grid>

            <Grid spacing={1} container xs={4} item>
              <Grid item>消費</Grid>
              <Grid item>
                <Switch
                  name="checkedC"
                  onChange={toggleAmountInputTyoe}
                  color="primary"
                  value={isInputTypeConsume}
                />
              </Grid>
              <Grid item>追加</Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Button variant="outlined" onClick={toggleOpenDetail} size="medium">
          {openDetail ? "詳細非表示" : "詳細表示"}
        </Button>

        <Container style={{ display: openDetail ? "block" : "none" }}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id="supplier-select-label">仕入先</InputLabel>
            <Select
              labelId="supplier-select-label"
              id="supplier-select"
              value={supplier}
              onChange={handleSupplierChange}
            >
              {suppliers &&
                suppliers
                  .sort((a, b) => a.name.localeCompare(b.name, "ja"))
                  .map((el, i) => (
                    <MenuItem value={el.name} key={i}>
                      {el.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id="category-select-label">分類</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={category}
              onChange={handleCategoryChange}
            >
              {categories &&
                categories
                  .sort((a, b) => a.name.localeCompare(b.name, "ja"))
                  .map((el, i) => (
                    <MenuItem value={el.name} key={i}>
                      {el.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="price">単価</InputLabel>
            <Input
              id="price"
              type={"number"}
              value={price}
              onChange={handlePriceChange}
              error={isPriceInvalid}
              startAdornment={<InputAdornment position="start">¥</InputAdornment>}
            />
            {isPriceInvalid && <InputErrorText message="入力は必須です" />}
          </FormControl>
          <FormControl fullWidth className={classes.formControl} required>
            <InputLabel htmlFor="price">最小発注量</InputLabel>
            <Input
              id="minimumOrderQuantity"
              type={"number"}
              value={minimumOrderQuantity}
              error={isMinmumOrderQuantityInvalid}
              onChange={handleMinimumOrderQuantityChange}
            />
            {isMinmumOrderQuantityInvalid && <InputErrorText message="入力は必須です" />}
          </FormControl>
          <DeleteStockFormDialog handleEditDialog={setOpen} stockId={props.stock.id} />
        </Container>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleUpdateButtonClicked} color="primary">
            更新
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
