import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { OrdersContext } from "../../contexts/orders";
import { EditOrderStatusFormDialog } from "../dialogs/order/EditOrderStatusFormDialog";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { OrderStatus } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    button: {
      margin: theme.spacing(1),
    },
    table: {
      minWidth: 650,
    },
    tableHeader: {
      fontWeight: "bold",
    },

    searchTextField: {
      minWidth: 300,
    },
  })
);

const statusSelectValues: OrderStatus[] = [
  OrderStatus.Registered,
  OrderStatus.Ordered,
  OrderStatus.Delivered,
];

export const OrderTabPanel: React.FC = () => {
  const classes = useStyles();
  const { orders } = useContext(OrdersContext);
  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

  const [item, setItem] = React.useState<string>("");
  const handleItemChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setItem(event.target.value as string);
  };

  const [status, setStatus] = React.useState<string>("all");
  const handleStatusSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string);
  };

  return (
    <div>
      <form onSubmit={preventDefault}>
        <FormControl className={classes.formControl}>
          <TextField
            id="standard-basic"
            label="品目名"
            className={classes.searchTextField}
            value={item}
            onChange={handleItemChange}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="supplier-select-label">ステータス</InputLabel>
          <Select
            value={status}
            onChange={handleStatusSelectChange}
            labelId="statu-select-label"
            id="status-select"
          >
            <MenuItem value={"all"}>全て</MenuItem>
            {statusSelectValues.map((el, i) => (
              <MenuItem value={el} key={i}>
                {el}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton aria-label="search" className={classes.button} disabled>
          <SearchIcon fontSize="large" />
        </IconButton>
      </form>
      <TableContainer component={Paper}>
        <Table aria-label="order-table" className={classes.table}>
          <TableHead>
            <TableRow className={classes.table}>
              <TableCell className={classes.tableHeader} align="left">
                発注品目
              </TableCell>
              <TableCell className={classes.tableHeader} align="left">
                発注先
              </TableCell>
              <TableCell className={classes.tableHeader} align="right">
                数量
              </TableCell>
              <TableCell className={classes.tableHeader} align="center">
                ステータス
              </TableCell>
              <TableCell className={classes.tableHeader} align="center">
                登録日
              </TableCell>
              <TableCell className={classes.tableHeader}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders &&
              orders
                .filter((order) => {
                  if (!item) {
                    return true;
                  } else {
                    return order.item.includes(item);
                  }
                })
                .filter((order) => {
                  if (!status || status === "all") {
                    return true;
                  } else {
                    return order.status === status;
                  }
                })
                .sort((a, b) => {
                  //最新のものから登録日が新しいものから表示
                  if (a.createdAt > b.createdAt) return -1;
                  if (a.createdAt < b.createdAt) return 1;
                  return 0;
                })
                .map((order, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">{order.item}</TableCell>
                    <TableCell align="left">{order.supplier}</TableCell>
                    <TableCell align="right">{order.quantity}</TableCell>
                    <TableCell align="center">{order.status}</TableCell>
                    <TableCell align="center">
                      {order.createdAt.toDate().toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <EditOrderStatusFormDialog order={order} />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
